import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TaskStatusComponent } from './components/task-status/task-status.component';
import { HttpInterceptor } from './interceptor/header-interceptor';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from '@ui-core/toast';
import { SpinnerModule } from '@igps/spinner';
import { ConvertTimePipe, PipeModule } from '@igps/pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { TimepickerComponent } from './shared/components/timepicker/timepicker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FilterSelectModule } from '@igps/filter-select';
import { EditTaskDatesPopUpComponent } from './components/edit-task-dates-pop-up/edit-task-dates-pop-up.component';
import { ParcelModule } from 'single-spa-angular/parcel';
import { FaIconsRegistry, IconModule, faClock } from '@ui-core/icon';
@NgModule({
  declarations: [
    AppComponent,
    TaskStatusComponent,
    TimepickerComponent,
    EditTaskDatesPopUpComponent
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    PipeModule,
    HttpClientModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ParcelModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    BrowserAnimationsModule,
    ToastModule,
    MatButtonModule,
    MatTooltipModule,
    FilterSelectModule,
    CommonModule,
    IconModule,
    SpinnerModule    ],
  providers: [
    DatePipe,
    ConvertTimePipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private faIconsRegistry: FaIconsRegistry) {
    this.faIconsRegistry.registerIcons([faClock]);
  }
}
