(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@igps/client-root-config"), require("@igps/file-info-header"));
	else if(typeof define === 'function' && define.amd)
		define(["@igps/client-root-config", "@igps/file-info-header"], factory);
	else if(typeof exports === 'object')
		exports["IGPS-Client-Processor-TaskStatus-UI"] = factory(require("@igps/client-root-config"), require("@igps/file-info-header"));
	else
		root["IGPS-Client-Processor-TaskStatus-UI"] = factory(root["@igps/client-root-config"], root["@igps/file-info-header"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__141__, __WEBPACK_EXTERNAL_MODULE__683__) => {
return 